<template>
  <div class="security-chgPwd-wrapper">
    <div class="security-chgPwd-wrapper-title">
      {{ $t('profile.chooseVerification') }}
    </div>
    <div class="security-chgPwd-wrapper-choose">
      <div class="security-chgPwd-wrapper-choose-item ub" @click="() => onChoose(1)" :class="{active: this.active === 1}" v-if="phone">
        <div class="avatar"></div>
        <div class="infos">
          <p>{{ phone }}</p>
          <span>{{ $t('profile.added') }}: {{ createTime }}</span>
        </div>
      </div>
      <div class="security-chgPwd-wrapper-choose-item ub" @click="() => onChoose(2)" :class="{active: this.active === 2}" v-if="email">
        <div class="avatar"></div>
        <div class="infos">
          <p>{{ email }}</p>
          <span>{{ $t('profile.added') }}: {{ createTime }}</span>
        </div>
      </div>
    </div>
    <van-button type="primary" block :color="active ? btnColor : defaultColor" :disabled="!active" class="btn-logout" @click="onNext">
      {{ $t('next') }}
    </van-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { sendEmailSms } from '@/api/auth.js'
export default {
  data () {
    return {
      active: null,
      btnColor: 'var(--primary-color)',
      defaultColor: 'var(--main-text-3)'
    }
  },
  computed: {
    phone () {
      return this.$store.state.userInfo?.mobile
    },
    phoneCode () {
      return this.$store.state.userInfo?.mobile_code
    },
    email () {
      return this.$store.state.userInfo?.email
    },
    createTime () {
      return this.$store.state.userInfo?.create_time ? this.formatDate(this.$store.state.userInfo?.create_time) : '--'
    },
    type () {
      return this.$route.params.type
    },
    ...mapGetters(['locale'])
  },
  methods: {
    onChoose (value) {
      this.active = value
    },
    onNext () {
      const params = {
        email: this.email
      }
      this.updateEmail(this.email)
      this.updatePhone('')
      this.updateMobileCode('')
      if (this.active === 1) {
        delete params.email
        params.mobile = this.phone
        params.mobile_code = this.phoneCode
        this.updateEmail('')
        this.updatePhone(this.phone)
        this.updateMobileCode(this.phoneCode)
      }
      sendEmailSms(params).then(res => {
        if (res.code === 0 && res.data.account_status) {
          const resData = res.data
          this.$store.dispatch('updateVerificationAccountStatus', resData.account_status)
          this.$store.dispatch('updateVerificationCountdown', +resData.expires_time)
          this.$store.dispatch('updateVerificationExpiresTime', new Date())
          this.$router.push(`/register/verification/${this.type}`)
        }
        this.$toast.clear()
      })
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      const options = { month: 'long', day: 'numeric', year: 'numeric' }
      return date.toLocaleDateString(this.locale.replace('_', '-'), options)
    },
    ...mapActions(['updatePhone', 'updateEmail', 'updateMobileCode'])
  }
}
</script>
<style lang="less">
.security-chgPwd-wrapper {
  padding: 0 30px;
  background-color: var(--background-color-4);
  .btn-logout {
    margin-top: 64px;
    border-radius: 8px;
  }
  &-title {
    padding-top: 20px;
    color: var(--main-text-1);
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 72px; /* 150% */
  }
  &-choose {
    margin-top: 48px;
    .active {
      border-color: var(--primary-color);
      background: url("~@/assets/images/profile/avatar-active.svg") no-repeat;
      background-position: right bottom;
    }
  }
  &-choose-item {
    margin-bottom: 32px;
    padding: 24px 32px;
    border-radius: 16px;
    border: 1px solid var(--divider-color);
    .avatar {
      margin-right: 24px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: var(--background-color-3);
    }
    .infos {
      p {
        color: var(--main-text-1);
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px; /* 150% */
      }
      span {
        color: var(--main-text-3);
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px; /* 140% */
      }
    }
  }
}
</style>
